/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Button, Heading, Paragraph, Panel, Logo } from 'components';
import polyglot from 'i18n';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import styles from './Hero.css';
import { getGA } from '../../../setupGA';

// Headingcontent and content wrappers below would ideally have to be moved to own atoms and included in the frontpage template
// This hero is no longer a hero :-/
// Variabelen "tekst" er hentet fra basen under "forside"

type Props = {
  tekst: String,
  rapportEn: String,
  rapportTo: String,
};

function innerHtml(tekst) {
  let result = { __html: tekst };
  if (!tekst) {
    result = {
      __html: `${polyglot.t('frontPage.heroSubParagraph1')} 
    
    ${polyglot.t('frontPage.heroSubParagraph2')}`,
    };
  }
  return result;
}

const Hero = ({ tekst, rapportEn, rapportTo }: Props) => {
  const [buttonDisabled, setButtonDisabled] = useState(
    !getCookieConsentValue('cookieConsent'),
  );

  const disableButton = true;

  function hideReloadWarning() {
    document.getElementById('reloadWarning').style.display = 'none';
  }
  function enablePointerEvents() {
    document.getElementById('background').style.pointerEvents = 'auto';
  }

  function unblurBackground() {
    document.getElementById('background').style.filter = 'blur(0px)';
  }

  function onPageLoad(boolean) {
    if (boolean) {
      hideReloadWarning();
      enablePointerEvents();
      unblurBackground();
    }
  }

  useEffect(() => {
    onPageLoad(getCookieConsentValue('cookieConsent'));
  });

  const onCookieConsent = () => {
    setButtonDisabled(false);
    getGA();
    hideReloadWarning();
    enablePointerEvents();
    unblurBackground();
  };

  const onCookieDecline = () => {
    enablePointerEvents();
    unblurBackground(true);
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.background} id="background">
        <Panel>
          <div className={styles.frontLogo}>
            <Logo
              frontpage
              target="_blank"
              href="http://www.statsbygg.no/Oppgaver/Statens-lokaler/"
            />
          </div>
          <Heading frontPage capital className={styles.heading}>
            {polyglot.t('frontPage.heroHeading')}
          </Heading>
          <Paragraph ingress className={styles.ingress}>
            {polyglot.t('frontPage.heroParagraph')}
          </Paragraph>
          <Heading capital className={styles.heading}>
            {polyglot.t('frontPage.siteDown')}
          </Heading>
          <div className={styles.login}>
            <Button
              kind="primary"
              minWidth
              to="/d/hjem"
              disabled={disableButton}
            >
              {polyglot.t('frontPage.loginButton')}
            </Button>
          </div>
          <div className={styles.declinedCookies} id="reloadWarning">
            <Paragraph bold className={styles.reloadWarning}>
              {polyglot.t('frontPage.declinedCookies')}
            </Paragraph>
          </div>
          <Paragraph bold className={styles.paragraph}>
            <span dangerouslySetInnerHTML={innerHtml(tekst)} />
          </Paragraph>
          {rapportEn && (
            <iframe
              width="100%"
              height="600px"
              title="rapportEn"
              src={rapportEn}
              allowFullScreen
            />
          )}
          {rapportTo && (
            <iframe
              width="100%"
              height="600px"
              title="rapportTo"
              src={rapportTo}
              allowFullScreen
            />
          )}
        </Panel>
      </div>
      <CookieConsent
        enableDeclineButton
        setDeclineCookie={false}
        buttonText={polyglot.t('frontPage.cookieConsent.acceptButton')}
        declineButtonText={polyglot.t('frontPage.cookieConsent.rejectButton')}
        cookieName="cookieConsent"
        style={{
          background: '#2B373B',
          width: 'fit-content',
          position: 'fixed',
          height: 'fit-content',
          left: 'none',
          top: '35%',
        }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}
        onAccept={() => onCookieConsent()}
        onDecline={() => onCookieDecline()}
      >
        {polyglot.t('frontPage.cookieConsent.description')}
      </CookieConsent>
    </section>
  );
};

export default Hero;
